import anime from '../../node_modules/animejs/lib/anime.es.js';
import aos from '../../node_modules/aos/dist/aos.js';

async function start() {
    // loading animation
    anime({
        targets: '#loading span',
        scale: 1.5,
        duration: 1000,
        color: 'rgb(255, 0, 0)',
        delay: function (el, i) {
            return 100 * i;
        },
        loop: true
    });

    document.getElementById(`footer_date`).textContent = new Date().getFullYear();

    if (document.readyState === 'ready' || document.readyState === 'complete') {
        await init();
    } else {
        document.onreadystatechange = function () {
            if (document.readyState == "complete") {
                init();
            }
        }
    }
}

async function init() {
    setupDonateModal();
    setupJoinUsModal();
    await setupVolunteerForm();
    await setupLandingPageStorySection();
    await setupStoryPage();
    setupTeamModal();
    setupMobileMenu();
    removeLoadingScreen();
}

function setupDonateModal() {
    const modal = document.getElementById("donate_modal");
    if (modal) {
        const close_modal = document.getElementById("close_donate_modal");
        const open_modal_btns = document.querySelectorAll(".open__donate__modal");

        close_modal.addEventListener(`click`, (e) => {
            e.preventDefault();

            modal.classList.add(`hidden`);
        })

        open_modal_btns.forEach((btn) => {
            btn.addEventListener(`click`, (e) => {
                e.preventDefault();

                modal.classList.remove(`hidden`);
            });
        });
    }
}

function setupJoinUsModal() {
    const modal = document.getElementById("join_modal");
    const data = { name: "", email: "", address: "", phone: "" };
    if (modal) {
        // Handle opening and closing modal
        const close_modal = document.getElementById("close_join_modal");
        const open_modal_btns = document.querySelectorAll(".open__join__modal");

        close_modal.addEventListener(`click`, (e) => {
            e.preventDefault();

            modal.classList.add(`hidden`);
        })

        open_modal_btns.forEach((btn) => {
            btn.addEventListener(`click`, (e) => {
                e.preventDefault();

                modal.classList.remove(`hidden`);
            });
        });

        // Handle modal navigation
        let selected_member = 0;
        const modal_form = document.getElementById("join_modal_form");
        const honorary_member_benefits = document.getElementById("join_modal_honorary_member_benefits");
        const corporate_member_benefits = document.getElementById("join_modal_corporate_member_benefits");
        const ordinary_member_benefits = document.getElementById("join_modal_ordinary_member_benefits");
        const submit_join_form = document.getElementById("submit_join_form");
        const modal_back_btn = document.querySelectorAll(`.join_modal_back_btn`);
        const modal_send_btn = document.querySelectorAll(`.join_modal_send_btn`);

        const member_options = document.querySelectorAll(`.selected_member_type`);
        member_options.forEach((item, index) => {
            item.addEventListener(`click`, () => {
                selected_member = index;
            });
        });

        submit_join_form.addEventListener('click', (e) => {
            e.preventDefault();

            const first_name = document.getElementById(`member_first_name`).value;
            const last_name = document.getElementById(`member_last_name`).value;
            const email = document.getElementById(`member_email`).value;
            const phone = document.getElementById(`member_phone`).value;
            const address = document.getElementById(`member_address`).value;

            if (first_name.trim().length === 0 || last_name.trim().length === 0 || email.trim().length === 0 || phone.trim().length === 0 || address.trim().length === 0) {
                document.getElementById(`join_form_error_msg`).classList.remove(`hidden`);
                document.getElementById(`join_form_error_msg`).textContent = "Please fill all the fields";
                return;
            }

            document.getElementById(`join_form_error_msg`).classList.add(`hidden`);

            data.name = `${first_name} ${last_name}`;
            data.email = email;
            data.phone = phone;
            data.address = address;

            switch (selected_member) {
                case 0:
                    modal_form.classList.add(`hidden`);
                    ordinary_member_benefits.classList.remove(`hidden`);
                    break;
                case 1:
                    modal_form.classList.add(`hidden`);
                    corporate_member_benefits.classList.remove(`hidden`);
                    break;
                case 2:
                    modal_form.classList.add(`hidden`);
                    honorary_member_benefits.classList.remove(`hidden`);
                    break;
                default:
                    break;
            }
        });
        modal_back_btn.forEach((item) => {
            item.addEventListener(`click`, (e) => {
                e.preventDefault();

                switch (selected_member) {
                    case 0:
                        ordinary_member_benefits.classList.add(`hidden`);
                        modal_form.classList.remove(`hidden`);
                        break;
                    case 1:
                        corporate_member_benefits.classList.add(`hidden`);
                        modal_form.classList.remove(`hidden`);
                        break;
                    case 2:
                        honorary_member_benefits.classList.add(`hidden`);
                        modal_form.classList.remove(`hidden`);
                        break;
                    default:
                        break;
                }
            });
        });
        modal_send_btn.forEach((item) => {
            item.addEventListener(`click`, (e) => {
                e.preventDefault();

                switch (selected_member) {
                    case 0:
                        ordinary_member_benefits.classList.add(`hidden`);
                        modal_form.classList.remove(`hidden`);
                        break;
                    case 1:
                        corporate_member_benefits.classList.add(`hidden`);
                        modal_form.classList.remove(`hidden`);
                        break;
                    case 2:
                        honorary_member_benefits.classList.add(`hidden`);
                        modal_form.classList.remove(`hidden`);
                        break;
                    default:
                        break;
                }
                // SEND EMAIL
                document.getElementById(`join_form_error_msg`).textContent = "Information sent. You'll be contacted as soon as your information is reviewed.";
                document.getElementById(`join_form_error_msg`).classList.remove(`hidden`);
            });
        });
    }
}

async function setupVolunteerForm() {
    const submit = document.getElementById(`submit_volunteer_form`);
    if (submit) {
        submit.addEventListener(`click`, async (e) => {
            e.preventDefault();

            const first_name = document.getElementById(`first_name`).value;
            const last_name = document.getElementById(`last_name`).value;
            const email = document.getElementById(`email`).value;
            const phone = document.getElementById(`phone`).value;

            if (first_name.trim().length === 0 || last_name.trim().length === 0 || email.trim().length === 0 || phone.trim().length === 0) {
                document.getElementById(`volunteer_form_error_msg`).textContent = "Please fill all sections";
                document.getElementById(`volunteer_form_error_msg`).classList.remove(`hidden`);
                return;
            }

            document.getElementById(`volunteer_form_error_msg`).classList.add(`hidden`);
            const data = {
                firstname: first_name,
                lastname: last_name,
                email: email,
                phone: phone
            };

            await fetch("send_mail.php", {
                method: "POST"
                , body: new URLSearchParams(`first_name=${data.firstname}&last_name=${data.lastname}&email=${data.email}&phone=${data.phone}`)
            }).then((response) => { if (response.ok) { return response.text(); } }).then((txt) => {
                console.log(txt);
            }).catch((err) => console.log(`Error fetching data ${err}`));

            // SEND MAIL
            document.getElementById(`volunteer_form_error_msg`).textContent = "Sent. Please wait for the email respose.";
            document.getElementById(`volunteer_form_error_msg`).classList.remove(`hidden`);
        });
    }
}

async function setupLandingPageStorySection() {
    const all_stories = document.getElementById("stories_section");
    if (all_stories) {
        all_stories.innerHTML = "";

        await fetch("data.php").then((response) => { if (response.ok) { return response.text(); } }).then((txt) => {
            const data = JSON.parse(txt);

            let content = ""
            data.data.forEach((elem, i) => {
                content += getStoryFigure(i, elem.src, elem.title, elem.description);
            });
            all_stories.innerHTML = content;
        }).catch((err) => console.log(`Error fetching data ${err}`));
    }
}

async function setupStoryPage() {
    const title = document.getElementById(`story_title`);
    if (title) {
        const index = Number.parseInt(window.location.search.at(4));

        if (!Number.isInteger(index) || index < 0) {
            window.location.href = `${window.location.origin}/stories.html?id=0`;
            return;
        }

        const desc = document.getElementById(`story_description`);
        const gallery = document.getElementById(`story_gallery`);
        gallery.innerHTML = "";
        const other_stories = document.getElementById(`other_stories`);
        other_stories.innerHTML = "";

        await fetch("data.php").then((response) => { if (response.ok) { return response.text(); } }).then((txt) => {
            const data = JSON.parse(txt);
            if (index >= data.data.length) {
                window.location.href = `${window.location.origin}/stories.html?id=${data.data.length - 1}`;
                return;
            }

            const formated_title = data.data[index].title.replace("<br>", " ");

            title.textContent = formated_title;
            desc.textContent = data.data[index].full_description;

            const pics = fetchStoryGallery(index);
            pics.forEach((src) => {
                const elem1 = document.createElement(`picture`);
                const elem2 = document.createElement(`img`);
                elem2.classList.add(`w-full`);
                elem2.src = src;
                elem2.alt = `${formated_title} picture`;

                elem1.classList.add(`w-full`);
                elem1.append(elem2);

                gallery.append(elem1);
            });

            let content = ""
            data.data.forEach((elem, i) => {
                if (index !== i) {
                    content += getStoryFigure(i, elem.src, elem.title, elem.description);
                }
            });
            other_stories.innerHTML = content;
        }).catch((err) => console.log(`Error fetching data ${err}`));
    }
}

function getStoryFigure(id, img_src, title, description) {
    return `<figure data-aos="zoom-in" class="basis-72 flex-grow-0 flex-shrink-0 text-start"><picture class="w-full"><img class="w-full" src="${img_src}" alt="${title}"></picture><figcaption class="w-full px-4 pb-4"><p class="mt-4 text-xl font-semibold leading-none">${title}</p><p class="mt-4">${description}</p><a class="w-32 flex items-center justify-between mt-4 mr-auto text-orange-500" href="stories.html?id=${id}">READ MORE <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.475 14.4751C8.3375 14.3376 8.2655 14.1626 8.259 13.9501C8.253 13.7376 8.31875 13.5626 8.45625 13.4251L12.1312 9.7501H3.75C3.5375 9.7501 3.35925 9.6781 3.21525 9.5341C3.07175 9.3906 3 9.2126 3 9.0001C3 8.7876 3.07175 8.60935 3.21525 8.46535C3.35925 8.32185 3.5375 8.2501 3.75 8.2501H12.1312L8.45625 4.5751C8.31875 4.4376 8.253 4.2626 8.259 4.0501C8.2655 3.8376 8.3375 3.6626 8.475 3.5251C8.6125 3.3876 8.7875 3.31885 9 3.31885C9.2125 3.31885 9.3875 3.3876 9.525 3.5251L14.475 8.4751C14.55 8.5376 14.6033 8.6156 14.6348 8.7091C14.6658 8.8031 14.6813 8.9001 14.6813 9.0001C14.6813 9.1001 14.6658 9.19385 14.6348 9.28135C14.6033 9.36885 14.55 9.4501 14.475 9.5251L9.525 14.4751C9.3875 14.6126 9.2125 14.6813 9 14.6813C8.7875 14.6813 8.6125 14.6126 8.475 14.4751Z" fill="rgb(249,115,22)" /></svg></a></figcaption></figure>`;
}

function setupTeamModal() {
    const member = document.querySelectorAll(`.team__member`);
    if (member) {
        member.forEach((item, index) => {
            item.addEventListener(`click`, (e) => {
                e.preventDefault();

                window.location.href = `${window.location.origin}/team.html?id=${index}`;
                return;
            });
        });
    }

    const name = document.getElementById(`team_member_name`);
    if (name) {
        const data = fetchTeamData();
        const index = Number.parseInt(window.location.search.at(4));

        if (!Number.isInteger(index) || index < 0 || index > (data.data.length - 1)) {
            window.location.href = window.location.origin;
        }

        document.getElementById(`team_member_pic`).src = data.data[index].pic;
        document.getElementById(`team_member_pic`).alt = data.data[index].name;
        name.textContent = data.data[index].name;
        document.getElementById(`team_member_details`).textContent = data.data[index].details;
    }
}

/* Mobile menu setup */
function setupMobileMenu() {
    // closing and opening functionality
    const menu_container = document.getElementById(`mobile_menu`);
    const btn = document.getElementById(`mobile_menu_btn`);
    const close_menu = document.getElementById("close_mobile_menu");

    if (close_menu) {
        close_menu.addEventListener('click', (e) => {
            e.preventDefault();

            menu_container.classList.toggle(`-translate-x-full`);
            // window.location.href = `${window.location.origin}#contacts`;
        });
    }

    btn.addEventListener('click', (e) => {
        e.preventDefault();

        menu_container.classList.toggle(`-translate-x-full`);
    });
}

/* Scroll animations */
function setupScrollAnimations() {
    aos.init({
        duration: 400,
        easing: 'ease-in-out',
        mirror: true,
    });
}

/* Remove loading screen */
function removeLoadingScreen() {
    document.querySelector(`body`).classList.remove(`max-h-screen`);
    document.getElementById(`loading`).classList.add(`hidden`);

    setupScrollAnimations();
}

function fetchTeamData() {
    return {
        data:
            [
                {
                    pic: "mwangi.36e6f407.jpg",
                    name: "Mwangi",
                    details: "Mwangi, the Foundation Chairman, went to school at Kamuyu and Mkungi Primary Schools (U/Gishu and Nyandarua, respectively), Kagumo High School, and is an agricultural engineer having studied Egerton College, New Mexico State University, University of Nairobi and Stockholm University. He also has a diploma in Transformational Church Leadership from Pan African University (PAC). He worked with Ministry of Agriculture at location, district, provincial and national levels. He also worked at KCDA, a coconut development government agency based at Mombasa and left a memorable record of milestones. A passionate water harvester, he managed and planned presidential soil conservation sites, designed and implemented community water projects, mobilized communities on environment and improvement of household economies, and managed construction of grain storage and market infrastructure. He has conducted training for officers at county, provincial, national and international levels on water harvesting for improved food security. Mwangi has been a committed church member of the AIC(Kinangop), Deliverance (Embu), JCC (Mombasa) and finally CCI(Juja). At the latter he was involved in church physical development projects, pastoral transport improvement and currently teaches Sunday School. At 65 years old, Mwangi's interest in the plight of the needy can be traced back to 1968 after a macabre incident where his family lived and which would forever change his life. However, his first ministry to widows and orphans was not until 1996 when he experienced first-hand the responsibility of building for a widow and her orphans. He is married to Rachael and they have two grown-up children and a grandson. His hobbies include travelling and hiking.",
                },
                {
                    pic: "angela.5ffd7bbb.jpg",
                    name: "Angela Karema",
                    details: "Angela Karema is the Foundation Secretary. She is a graduate with BEd from University of Nairobi, and MSc and PhD both in HRM from Jomo Kenyatta University of Agriculture and Technology (JKUAT). She has worked in JKUAT in various positions including Assistant Administrator in the Research & Extension department, Senior Administrator in Community & Collaboration Department and is currently a lecturer and Examination officer for the ETLM- JKUAT. She is am a member of CCI Joy Family Church, Juja Branch, where she currently chairs the Sunday School Ministry, Pastors Welfare Ministry and is the Secretary to the Child Development Centre, a project supported by Compassion International. As a Board member of Tujali Wajane na Yatima Foundation, she finds fulfillment in standing for the less privileged in society, through a call she got from the year 2000 while in college, when she took initiatives to organize 'help missions' to orphanages with the Christian Union members. Her passion was soon identified by FOCUS Kenya, who offered her an opportunity for internship to work with TULIP NGO in Korogocho that catered for education of the poor in the slums. She has since cherished opportunities to work to uplift the lives of the less privileged in society including orphans and the windowed. Angela is married to Boniface and they have four children. Her hobbies include travelling, reading and badminton.",
                },
                {
                    pic: "muthoni.880897e4.jpg",
                    name: "Lucy Muthoni Kinuthia",
                    details: "Lucy Muthoni Kinuthia is the Foundation Treasurer and holds diplomas and certificates in various fields and has a bachelor in Education in early childhood at Presbyterian University of East Africa (PUEA). She has been a teacher and has held various positions including Deputy head teacher at Gachoka Primary school and the head teacher at Mirimaini Primary school until she retired in June 2022. In her last position Lucy headed the lobby to a team from Korea to  provide lunches 250 vulnerable pupils. She was also instrumental in mentoring parents and pupils, and lobbied for their help from Penta flowers, Juja Constituency Office and Friends of Mirimaini. She is a member of Living Faith Tabernacle Juja, and was ordained as an elder in 2018. In June 2022 she was recommended to join Kiaora Location team of elders. Lucy is passionate in meeting the needs of less fortunate and was quite active during the Covid-19 pandemic in providing food to the hungry. Currently she is training a cohort of 18–40-year-old individuals fulfil their dreams through enhanced hands-on skills. Her hobbies include singing.",
                },
                {
                    pic: "gitonga.ca3e0552.jpg",
                    name: "Pst. Loyford Gitonga",
                    details: "Pastor Loyford Gitonga obtained his diploma in mechanical engineering from Thika Technical Training Institute in 2018. However, he has been working as an engineering Laboratoty Technologist at Jomo Kenyatta University of Agriculture and Technology (JKUAT) since 2005. A born again Christian, Gitonga received his call to serve as a pastor in 1990, and has been a pastor at Christian Church International Mirimaini from 1997. His passion for the needy (widows, orphans and the less fortunate) started around 1990. Gironga is married to Wanjiku and they have two grown-up children. His interests include hiking.",
                },
                {
                    pic: "thuo.40a5e87b.jpg",
                    name: "Apst. Gilbert Thuo",
                    details: "Apostle Gilbert Thuo is the Senior pastor at Refuge City Church, Juja. He started outreach to widows and orphans in 2013, and from 2014 he was serving God in other churches such as JCC in Thika and Deliverance Church Kalimoni, and eventually started Refuge City Church in 2019. After graduating from high school in 1998, Apostle Thuo continued with studies and graduated with a diploma from African Missions Training Institute (AMTI) and is currently studying towards a bachelor’s degree at the same institute. Apostle Thuo’s outreach includes supporting technical development of the proposed Salem Rescue Centre in Ruiru, and providing food to Amicable Children’s Home in Mangu. Aged 42, Gilbert is married to Everlyne and they have two children Myles and Chloe.",
                },
                {
                    pic: "lennox.61a2f55d.jpg",
                    name: "Rev. Lennox Kalu",
                    details: "Rev Lennox Kalu sat his Ordinary Level examination at Alliance High School and proceeded to study Christian Leadership at Word of Faith University and World Impact Bible Institute respectively. He is an ordained minister with Psalms Christian Ministry. He received his calling and passion for windows and orphans after starting the church in Mazeras, Kilifi County and realized that some widows who had joined the church were needy and required support. He has been mobilizing the church to support them with the little resources available. He is married to Anne Waithira and they are blessed with 3 children. His hobbies include reading books, listening and watching preaching videos, and helping the needy in the community."
                },
                {
                    pic: "muiruri.791bb52a.jpg",
                    name: "Rev. Peter Muiruri",
                    details: "Rev Peter Muiruri was born in Kiambu in 1930 and studied up to standard 8 in the old education system in Kenya at the time. He received the call to serve God in 1956 and has ministered in the AIC church throughout. He was ordained as a reverend in 1978, and retired in 1998. Muiruri has a big footprint having started a total of 25 churches in various areas - Ol Kalou, Njabini, North Kinangop, Geta and Naivasha. Even after retiring, he still plays a major role in the local church. The call to support the needy started in 1980s while serving in Kinangop, Nyandarua County. Through this divine call, he was able to buy sheep for needy widows and pay fees for orphans. A widower, he has two children and more than 10 grandchildren."
                },
            ]
    }
}

function fetchStoryGallery(id) {
    const result = {
        data: [
            ["kamuyu_main.10e6f6fa.jpg", "kamuyu_0.46309709.jpg", "kamuyu_1.5292cbbd.jpg", "kamuyu_2.b4126b34.jpg", "kamuyu_3.2d21b793.jpg", "kamuyu_4.b077eedf.jpg", "kamuyu_5.b3ab1a2d.jpg", "kamuyu_6.49e22172.jpg", "kamuyu_7.4ec08dbe.jpg", "kamuyu_8.9917bf30.jpg", "kamuyu_9.e06c6430.jpg", "kamuyu_10.58901010.jpg", "kamuyu_11.70808d1f.jpg"],
            ["stories-placeholders.086b5143.jpg", "stories-placeholders.086b5143.jpg", "stories-placeholders.086b5143.jpg", "stories-placeholders.086b5143.jpg", "stories-placeholders.086b5143.jpg"],
            ["amicable_main.a01548e6.jpg", "amicable_0.64b34b44.jpg", "amicable_1.2c95d979.jpg", "amicable_2.2d42523d.jpg", "amicable_3.fed91765.jpg"],
            ["salem_main.bf50cd9a.jpg", "salem_0.0378b81b.jpg", "salem_1.d7319ba3.jpg", "salem_2.2247313e.jpg", "salem_3.391818cd.jpg"]
        ]
    };

    return result.data[id];
}

// function fetchStories() {
//     const xhttp = new XMLHttpRequest();

//     xhttp.onreadystatechange = function () {
//         if (this.readyState == 4 && this.status == 200) {
//             return JSON.parse(this.responseText);
//         }
//     };

//     xhttp.open("GET", "data.php", false);
//     xhttp.send();
//     // let resp = await fetch("data.php").then(resp => resp.json()).then((err) => console.log(err));
//     // return resp;
// }

start();